<template>
    <div class="quartz">
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <span>承运商信息</span>
            </div>
            <div class="text item">
                <el-form :model="dataForms"  ref="dataForms" label-width="100px" class="demo-ruleForm" size="mini">
                    <el-row>
                        <el-col :span="6">
                            <el-form-item label="承运商名称" prop="companyName">
                                <el-input v-model="dataForms.companyName" :disabled="true"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="承运商简称" prop="nickname">
                                <el-input v-model="dataForms.nickname"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="营业执照号码" prop="license">
                                <el-input v-model="dataForms.license" :disabled="true"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="承运商地址" prop="address">
                                <el-input v-model="dataForms.address"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="6">
                            <el-form-item label="合同编号" prop="contractNo">
                                <el-input v-model="dataForms.contractNo" :disabled="true"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="法人身份证号" prop="identityNo">
                                <el-input v-model="dataForms.identityNo"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="联系方式" prop="phone">
                                <el-input v-model="dataForms.phone"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="座机电话" prop="telephone">
                                <el-input v-model="dataForms.telephone"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="6">
                            <el-form-item label="审核状态" prop="verify">
                                <el-select v-model="dataForms.verify" :disabled="true">
                                    <el-option label="未审核" value="01"></el-option>
                                    <el-option label="审核通过" value="02"></el-option>
                                    <el-option label="审核失败" value="03"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="公司状态" prop="status">
                                <el-select v-model="dataForms.status" :disabled="true">
                                    <el-option label="启用" value="1"></el-option>
                                    <el-option label="冻结" value="0"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="公司类型" prop="menuType">
                                <el-select v-model="dataForms.companyType" :disabled="true">
                                    <el-option label="普通用户" value="0"></el-option>
                                    <el-option label="承运商/司机" value="1"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="公司分属模块" prop="parent">
                                <el-select v-model="dataForms.moduleId" :disabled="true">
                                    <el-option label="未分配" value="0"></el-option>
                                    <el-option label="城市配" value="1"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="6">
                            <el-form-item label="营业执照附件照片">
                                <el-image
                                        style="width: 100px; height: 100px"
                                        :src="imgurl_s"
                                        v-if="imgflagmodel"
                                        :preview-src-list="srcList">
                                </el-image>
                                <el-link v-if="imgflagdel" icon="el-icon-delete" @ @click="delimg">删除</el-link>
                                <el-upload
                                        class="upload"
                                        v-if="imgflag"
                                        :class="{ hide: hideUp,uoloadSty:showBtnImg,disUoloadSty:noneBtnImg}"
                                        :action="getUploadUrl"
                                        list-type="picture-card"
                                        :limit="1"
                                        accept="image/*"
                                        name="image"
                                        :on-success="handleUpSuccess"
                                        :on-error="handleUpError"
                                        :on-change="handleUpUpload"
                                        :on-preview="handleUpPreview"
                                        :on-remove="handleUpRemove">
                                    <i class="el-icon-plus"></i>
                                </el-upload>
                                <el-dialog :visible.sync="dialogVisible">
                                    <img width="100%" :src="dialogImageUrl" alt="">
                                </el-dialog>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="合同附件照片">
                                <el-image
                                        style="width: 100px; height: 100px"
                                        :src="imgurl_s_contractUrl"
                                        v-if="imgflagmodel1"
                                        :preview-src-list="srcList1">
                                </el-image>
                                <el-link v-if="imgflagdel1" icon="el-icon-delete" @ @click="delimg1">删除</el-link>
                                <el-upload
                                        class="upload"
                                        v-if="imgflag1"
                                        :class="{ hide: hideUp1,uoloadSty:showBtnImg1,disUoloadSty:noneBtnImg1}"
                                        :action="getUploadUrl"
                                        list-type="picture-card"
                                        :limit="1"
                                        accept="image/*"
                                        name="image"
                                        :on-success="handleUpSuccess1"
                                        :on-error="handleUpError1"
                                        :on-change="handleUpUpload1"
                                        :on-preview="handleUpPreview1"
                                        :on-remove="handleUpRemove1">
                                    <i class="el-icon-plus"></i>
                                </el-upload>
                                <el-dialog :visible.sync="dialogVisible1">
                                    <img width="100%" :src="dialogImageUrl1" alt="">
                                </el-dialog>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="公司法人身份证正面照片">
                                <el-image
                                        style="width: 100px; height: 100px"
                                        :src="imgurl_s_identityPositiveUrl"
                                        v-if="imgflagmodel2"
                                        :preview-src-list="srcList2">
                                </el-image>
                                <el-link v-if="imgflagdel2" icon="el-icon-delete" @ @click="delimg2">删除</el-link>
                                <el-upload
                                        class="upload"
                                        v-if="imgflag2"
                                        :class="{ hide: hideUp2,uoloadSty:showBtnImg2,disUoloadSty:noneBtnImg2}"
                                        :action="getUploadUrl"
                                        list-type="picture-card"
                                        :limit="1"
                                        accept="image/*"
                                        name="image"
                                        :on-success="handleUpSuccess2"
                                        :on-error="handleUpError2"
                                        :on-change="handleUpUpload2"
                                        :on-preview="handleUpPreview2"
                                        :on-remove="handleUpRemove2">
                                    <i class="el-icon-plus"></i>
                                </el-upload>
                                <el-dialog :visible.sync="dialogVisible2">
                                    <img width="100%" :src="dialogImageUrl2" alt="">
                                </el-dialog>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="公司法人身份证反面照片">
                                <el-image
                                        style="width: 100px; height: 100px"
                                        :src="imgurl_s_identityBackUrl"
                                        v-if="imgflagmodel3"
                                        :preview-src-list="srcList3">
                                </el-image>
                                <el-link v-if="imgflagdel3" icon="el-icon-delete" @ @click="delimg3">删除</el-link>
                                <el-upload
                                        class="upload"
                                        v-if="imgflag3"
                                        :class="{ hide: hideUp3,uoloadSty:showBtnImg3,disUoloadSty:noneBtnImg3}"
                                        :action="getUploadUrl"
                                        list-type="picture-card"
                                        :limit="1"
                                        accept="image/*"
                                        name="image"
                                        :on-success="handleUpSuccess3"
                                        :on-error="handleUpError3"
                                        :on-change="handleUpUpload3"
                                        :on-preview="handleUpPreview3"
                                        :on-remove="handleUpRemove3">
                                    <i class="el-icon-plus"></i>
                                </el-upload>
                                <el-dialog :visible.sync="dialogVisible3">
                                    <img width="100%" :src="dialogImageUrl3" alt="">
                                </el-dialog>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="6">
                            <el-form-item label="创建人" prop="creator">
                                <el-input v-model="dataForms.creator" :disabled="true"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="创建时间" prop="createTime">
                                <el-input v-model="dataForms.createTime" :disabled="true"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="修改人" prop="modifier">
                                <el-input v-model="dataForms.modifier" :disabled="true"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="修改时间" prop="modifyTime">
                                <el-input v-model="dataForms.modifyTime" :disabled="true"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col style="text-align: center">
                            <el-button type="primary" size="mini" :disabled="platFormFlag" @click="savewaybill">更新信息</el-button>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
        </el-card>
    </div>
</template>
<script>
    import axios from 'axios'
    import qs from 'qs'
    export default {
        data(){
            return{
                tydvalue:true,
                transStatusvalue:false,
                dataForms:{
                    companyId:'',
                    companyName: '',
                    nickname:'',
                    license:'',
                    address:'',
                    contractNo:'',
                    identityNo:'',
                    phone:'',
                    telephone:'',
                    verify:'',
                    status: '',
                    companyType:'',
                    moduleId:'',
                    filesId:'',
                    licenseUrl:'',
                    contractUrl:'',
                    identityPositiveUrl:'',
                    identityBackUrl:'',
                    creator:'',
                    modifier:'',
                    createTime:'',
                    modifyTime:''
                },
                hideUp:false,
                hideUp1:false,
                hideUp2:false,
                hideUp3:false,
                hideBack:false,
                hideBusiness:false,
                imagesUp:[],
                imagesBack:[],
                imagesBusiness:[],
                fileList:[],
                dialogImageUrl:'',
                dialogImageUrl1:'',
                dialogImageUrl2:'',
                dialogImageUrl3:'',
                dialogVisible: false,
                dialogVisible1: false,
                dialogVisible2: false,
                dialogVisible3: false,
                showBtnImg:true,
                showBtnImg1:true,
                showBtnImg2:true,
                showBtnImg3:true,
                noneBtnImg:false,
                noneBtnImg1:false,
                noneBtnImg2:false,
                noneBtnImg3:false,
                limitCountImg:1,
                limitCountImg1:1,
                limitCountImg2:1,
                limitCountImg3:1,
                listKeyword:'',
                imgflag:false,
                imgflag1:false,
                imgflag2:false,
                imgflag3:false,
                imgurl_s:'',
                imgurl_s_contractUrl:'',
                imgurl_s_identityPositiveUrl:'',
                imgurl_s_identityBackUrl:'',
                imgflagdel:true,
                imgflagdel1:true,
                imgflagdel2:true,
                imgflagdel3:true,
                imgflagmodel:true,
                imgflagmodel1:true,
                imgflagmodel2:true,
                imgflagmodel3:true,
                srcList: [],
                srcList1: [],
                srcList2: [],
                srcList3: [],
                platFormFlag:true
            }
        },
        computed:{
            getUploadUrl(){
                let filePath = `${this.$filePath}`;
                return filePath
            }
        },
        mounted:function () {
            axios.get(`${this.$apiPath}/api/urban/carriers/queryCarrier`).then(res=>{
                if(res.data.success == true){
                    this.platFormFlag =false;
                    if(res.data.data.status == true){
                        res.data.data.status = '1';
                    }else if(res.data.data.status == false){
                        res.data.data.status = '0';
                    }
                    res.data.companyType = JSON.stringify(res.data.data.companyType);
                    res.data.moduleId = JSON.stringify(res.data.data.moduleId);
                    res.data.createTime = this.$moment(res.data.data.createTime).format("YYYY-MM-DD HH:mm:ss");
                    res.data.modifyTime = this.$moment(res.data.data.modifyTime).format("YYYY-MM-DD HH:mm:ss");
                    this.dataForms = res.data.data;
                    if(res.data.data.licenseUrl == null || res.data.data.licenseUrl == '' || res.data.data.licenseUrl == 'null'){
                        this.imgflag = true;
                        this.imgflagmodel = false;
                        this.imgflagdel = false;

                    }else{
                        this.imgflag = false;
                        this.imgflagmodel = true;
                        this.imgflagdel = true;
                        this.imgurl_s = `${this.$imageiPPath}`+res.data.data.licenseUrl;
                        this.srcList.push(`${this.$imageiPPath}`+res.data.data.licenseUrl);
                    }
                    if(res.data.data.contractUrl == null || res.data.data.contractUrl == '' || res.data.data.contractUrl == 'null'){
                        this.imgflag1 = true;
                        this.imgflagmodel1 = false;
                        this.imgflagdel1 = false;

                    }else{
                        this.imgflag1 = false;
                        this.imgflagmodel1 = true;
                        this.imgflagdel1 = true;
                        this.imgurl_s_contractUrl = `${this.$imageiPPath}`+res.data.data.contractUrl;
                        this.srcList1.push(`${this.$imageiPPath}`+res.data.data.contractUrl);
                    }
                    if(res.data.data.identityPositiveUrl == null || res.data.data.identityPositiveUrl == '' || res.data.data.identityPositiveUrl == 'null'){
                        this.imgflag2 = true;
                        this.imgflagmodel2 = false;
                        this.imgflagdel2 = false;

                    }else {
                        this.imgflag2 = false;
                        this.imgflagmodel2 = true;
                        this.imgflagdel2 = true;
                        this.imgurl_s_identityPositiveUrl = `${this.$imageiPPath}`+res.data.data.identityPositiveUrl;
                        this.srcList2.push(`${this.$imageiPPath}`+res.data.data.identityPositiveUrl);
                    }
                    if(res.data.data.identityBackUrl == null || res.data.data.identityBackUrl == '' || res.data.data.identityBackUrl == 'null'){
                        this.imgflag3 = true;
                        this.imgflagmodel3 = false;
                        this.imgflagdel3 = false;
                    }else {
                        this.imgflag3 = false;
                        this.imgflagmodel3 = true;
                        this.imgflagdel3 = true;
                        this.imgurl_s_identityBackUrl = `${this.$imageiPPath}`+res.data.data.identityBackUrl;
                        this.srcList3.push(`${this.$imageiPPath}`+res.data.data.identityBackUrl);
                    }
                }else {
                    this.$alert(res.data.msg, '提示', {
                        confirmButtonText: '确定',
                    });
                    this.platFormFlag = true;
                }
            })
        },
        methods:{
            savewaybill(){
                //运单新增
                const data = new URLSearchParams();
                data.append('companyId', this.dataForms.companyId);
                data.append('companyName', this.dataForms.companyName);
                data.append('nickname', this.dataForms.nickname);
                data.append('license', this.dataForms.license);
                data.append('address', this.dataForms.address);
                data.append('contractNo', this.dataForms.contractNo);
                data.append('identityNo', this.dataForms.identityNo);
                data.append('phone', this.dataForms.phone);
                data.append('telephone', this.dataForms.telephone);
                data.append('verify', this.dataForms.verify);
                data.append('status', this.dataForms.status);
                data.append('companyType', this.dataForms.companyType);
                data.append('moduleId', this.dataForms.moduleId);
                data.append('filesId', this.dataForms.filesId);
                data.append('licenseUrl', this.dataForms.licenseUrl);
                data.append('contractUrl', this.dataForms.contractUrl);
                data.append('identityPositiveUrl', this.dataForms.identityPositiveUrl);
                data.append('identityBackUrl', this.dataForms.identityBackUrl);
                axios({
                    method: 'post',
                    url: `${this.$apiPath}/api/urban/carriers/updateCarriers`,
                    data:data
                }).then(res=> {
                    if(res.data.success == true){
                        this.$message({
                            type: 'success',
                            message: '更新成功'
                        });
                    }else {
                        this.$alert(res.data.msg, '提示', {
                            confirmButtonText: '确定',
                        });
                    }


                });
            },
            //---------------------------------------营业执照副本--------------------------------
            handleUpSuccess(response, file, fileList) {
                this.dialogImageUrl = `${this.$imageiPPath}`+response.data;
                this.dataForms.licenseUrl = response.data;
                this.hideUp = fileList.length == 1;
                this.$message.success(response.msg);
            },
            handleUpError(err, file, fileList) {},
            // 删除图片
            handleUpRemove(file, fileList) {
                this.hideUp = false;
                this.dataForms.licenseUrl = '';
                this.noneBtnImg = fileList.length >= this.limitCountImg;
            },
            // 预览图片
            handleUpPreview(file) {
                this.dialogVisible = true;
            },
            // 上传图片
            handleUpUpload(file, fileList) {
                this.hideUp = true;
                this.noneBtnImg = fileList.length >= this.limitCountImg;
            },
            delimg(){
                this.imgflag = true;
                this.imgflagdel = false;
                this.imgflagmodel = false;
                this.dataForms.licenseUrl = '';
            },
            //---------------------------------------合同附件--------------------------------
            handleUpSuccess1(response, file, fileList) {
                this.dialogImageUrl1 = `${this.$imageiPPath}`+response.data;
                this.dataForms.contractUrl = response.data;
                this.hideUp1 = fileList.length == 1;
                this.$message.success(response.msg);
            },
            handleUpError1(err, file, fileList) {},
            // 删除图片
            handleUpRemove1(file, fileList) {
                this.hideUp1 = false;
                this.dataForms.contractUrl = '';
                this.noneBtnImg1 = fileList.length >= this.limitCountImg1;
            },
            // 预览图片
            handleUpPreview1(file) {
                this.dialogVisible1 = true;
            },
            // 上传图片
            handleUpUpload1(file, fileList) {
                this.hideUp1 = true;
                this.noneBtnImg1 = fileList.length >= this.limitCountImg1;
            },
            delimg1(){
                this.imgflag1 = true;
                this.imgflagdel1 = false;
                this.imgflagmodel1 = false;
                this.dataForms.contractUrl = '';
            },
            //---------------------------------------法人身份证正面--------------------------------
            handleUpSuccess2(response, file, fileList) {
                this.dialogImageUrl2 = `${this.$imageiPPath}`+response.data;
                this.dataForms.identityPositiveUrl = response.data;
                this.hideUp2 = fileList.length == 1;
                this.$message.success(response.msg);
            },
            handleUpError2(err, file, fileList) {},
            // 删除图片
            handleUpRemove2(file, fileList) {
                this.hideUp2 = false;
                this.dataForms.identityPositiveUrl = '';
                this.noneBtnImg2 = fileList.length >= this.limitCountImg2;
            },
            // 预览图片
            handleUpPreview2(file) {
                this.dialogVisible2 = true;
            },
            // 上传图片
            handleUpUpload2(file, fileList) {
                this.hideUp2 = true;
                this.noneBtnImg2 = fileList.length >= this.limitCountImg2;
            },
            delimg2(){
                this.imgflag2 = true;
                this.imgflagdel2 = false;
                this.imgflagmodel2 = false;
                this.dataForms.identityPositiveUrl = '';
            },
            //---------------------------------------法人身份证正面--------------------------------
            handleUpSuccess3(response, file, fileList) {
                this.dialogImageUrl3 = `${this.$imageiPPath}`+response.data;
                this.dataForms.identityBackUrl = response.data;
                this.hideUp3 = fileList.length == 1;
                this.$message.success(response.msg);
            },
            handleUpError3(err, file, fileList) {},
            // 删除图片
            handleUpRemove3(file, fileList) {
                this.hideUp3 = false;
                this.dataForms.identityBackUrl = '';
                this.noneBtnImg3 = fileList.length >= this.limitCountImg3;
            },
            // 预览图片
            handleUpPreview3(file) {
                this.dialogVisible3 = true;
            },
            // 上传图片
            handleUpUpload3(file, fileList) {
                this.hideUp3 = true;
                this.noneBtnImg3 = fileList.length >= this.limitCountImg3;
            },
            delimg3(){
                this.imgflag3 = true;
                this.imgflagdel3 = false;
                this.imgflagmodel3 = false;
                this.dataForms.identityBackUrl = '';
            },
        }
    }
</script>
<style>
    .waybillDo .grid-content{
        height: 100%;
    }
    .waybillDo .box-card{
        height: 100%;
    }
    .waybillDo .el-card__header{
        padding: 5px 10px;
    }
    .waybillDo .el-card__body{
        padding: 5px;
    }
    .uoloadSty .el-upload--picture-card{
        width:157px;
        height:110px;
        line-height:110px;
    }
    .disUoloadSty .el-upload--picture-card{
        display:none;   /* 上传按钮隐藏 */
    }
    /* 加载前 */
    .el-image__placeholder {
        background: url('../../assets/loading.gif') no-repeat 50% 50% !important;
        background-size: 50% !important;
    }
</style>
<style scoped>
    .quartz{
        width: 100%;
        height: 100%;
    }
    .quartz .el-card{
        width: 100%;
        height: 100%;
    }
    .text {
        font-size: 14px;
    }
    .item {
        margin-bottom: 18px;
    }
    .clearfix:before,
    .clearfix:after {
        display: table;
        content: "";
    }
    .clearfix:after {
        clear: both
    }

    .box-card {
        width: 480px;
    }
</style>
